/*
--breakpoint-xxl: 1440px;
--breakpoint-xxxl: 1600px;
*/

body { padding-top: 52px; }
.fixed-top { z-index: 999; }
input[type=range]::-moz-range-track {
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  border: solid 1px;
  height: 18px;
  width: 9px;
  border-radius: 0;
  border-color: rgb(153, 153, 153);
  background: lightgrey;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
  outline: 1px solid white;
  outline-offset: -1px;
}


@media (min-width: 1320px) {
  .container {
    max-width: 1310px;
	}
}
@media (min-width: 1355px) {
 	.wtn-GC-left {
		text-align: right !important;
	}
}

@media (min-width: 1440px) {
  .container {
    max-width: 1310px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1480px;
  }
}
.wtn-dimiss {
  /* background-color: rgb(222, 224, 228); */
  top: 74px;
  color: rgb(97, 218, 251);
  /* display: none; */
  cursor: pointer;
  position: fixed;
  right: 15px;
  z-index: 3;
  box-shadow: rgba(101, 131, 119, 0.3) 0px 0px 20px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(97, 218, 251);
  border-image: initial;
}
.wtn-menu-absolute {
  top: 0px;
  cursor: pointer;
  position: absolute;
  right: 12px;
  z-index: 3;
}
.wtn-dimiss-absolute {
  /* background-color: rgb(222, 224, 228); */
  top: 16px;
  color: rgb(97, 218, 251);
  /* display: none; */
  cursor: pointer;
  position: absolute;
  right: 16px;
  z-index: 3;
  box-shadow: rgba(101, 131, 119, 0.3) 0px 0px 8px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(97, 218, 251);
  border-image: initial;
}
.wtn-dimiss-absolute-disclaimer {
  /* background-color: rgb(222, 224, 228); */
  top: 18px;
  color: rgb(97, 218, 251);
  /* display: none; */
  cursor: pointer;
  position: absolute;
  right: 12px;
  z-index: 3;
  box-shadow: rgba(101, 131, 119, 0.3) 0px 0px 8px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(97, 218, 251);
  border-image: initial;
}
.wtn-settings-toggler {
  background-color: rgb(45, 63, 104);
  bottom: 44px;
  color: rgb(97, 218, 251);
  /* display: none; */
  cursor: pointer;
  position: fixed;
  right: 20px;
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
  border-image: initial;
}
body {
  background-color: #f8f9fa; 
}
.backgroundColor {
  background-color: #f8f9fa; 
 
}
.editableValue {
  cursor: pointer;
}
.clickableValue {
  cursor: pointer;
}
.readonlyValue {
  color: #6c757d !important;
}
.gasComponentMinWidth {
  min-width: 22em;
}